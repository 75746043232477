<svg width="100%" height="100%" viewBox="0 0 32 32">
	<circle cx="10" cy="12" r="2" fill="currentColor" />
	<circle cx="16" cy="9" r="2" fill="currentColor" />
	<circle cx="22" cy="12" r="2" fill="currentColor" />
	<circle cx="23" cy="18" r="2" fill="currentColor" />
	<circle cx="19" cy="23" r="2" fill="currentColor" />
	<path
		fill="currentColor"
		d="M16.54 2A14 14 0 0 0 2 16a4.82 4.82 0 0 0 6.09 4.65l1.12-.31a3 3 0 0 1 3.79 2.9V27a3 3 0 0 0 3 3a14 14 0 0 0 14-14.54A14.05 14.05 0 0 0 16.54 2Zm8.11 22.31A11.93 11.93 0 0 1 16 28a1 1 0 0 1-1-1v-3.76a5 5 0 0 0-5-5a5.07 5.07 0 0 0-1.33.18l-1.12.31A2.82 2.82 0 0 1 4 16A12 12 0 0 1 16.47 4A12.18 12.18 0 0 1 28 15.53a11.89 11.89 0 0 1-3.35 8.79Z"
	/>
</svg>
